<template>
  <div class="container">
    <h2 class="firstTitle">Livraison sécurisée</h2>
    <p>
      Le délai de fabrication et de livraison est de 3 à 6 semaines maximum.
      Nous nous efforçons d'écourter ce délai.
    </p>
    <ul>
      <li>Votre colis est assuré à hauteur de la valeur du bijou</li>
      <li>
        Votre colis vous est délivré par Colissimo, transporteur national
        reconnu pour sa fiabilité et déjà utilisé par de nombreuses marques.
      </li>
      <li>
        Vous avez la garantie que votre colis vous sera remis en mains propres
        contre signature
      </li>
      <li>
        Le colis est envoyé en toute confidentialité (aucune mention apparente
        de la marque et du produit)
      </li>
    </ul>
    <h2>Livraison gratuite</h2>
    <ul>
      <li>
        La livraison est entièrement gratuite en France métropolitaine. En
        dehors, n'hésitez pas à nous contacter pour organiser en direct la
        livraison et vous informer du supplément qui vous sera facturé.
      </li>
    </ul>
    <h2>Retrait à Lyon</h2>
    <ul>
      <li>
        Pour toute commande passée sur notre site ou prise directement lors d'un
        rendez-vous. Il est possible de la récupérer à Lyon directement en main
        propre, le retrait s'effectuera sur rendez-vous uniquement.
      </li>
    </ul>
    <h2>Échanges et retours</h2>
    <p>
      Il est important de nous contacter au préalable pour organiser le retour
      de la pièce et le remboursement, nous vous accompagnerons dans la
      procédure :
    </p>
    <ul>
      <li>
        Vous bénéficiez d’un délai de 14 jours pour nous retourner le bijou pour
        lequel vous souhaitez être remboursé. Les frais de retour sont à votre
        charge.
      </li>
      <li>Le bijou ne doit pas avoir été personnalisé et/ou gravé.</li>
      <li>
        Le bijou doit être en parfait état et ne pas avoir été porté, ni abîmé,
        ni sali.
      </li>
      <li>
        Le bijou doit être retourné dans son écrin et emballage d'origine.
      </li>
      <li>
        Aucun remboursement ne sera effectué jusqu’à ce que nous ayons reçu et
        contrôlé la pièce.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Retours et livraison",
    link: [{ rel: "canonical", href: "https://sunjewelry.fr/retours-et-livraison" }],
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 5% 10%;

  .firstTitle {
    padding-top: 3%;
  }
}
</style>